<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section>
			<div v-for="(check_message, index) in messages" :key="index">
				<div v-if="show_all || [0, 1, 2, 3, 4].includes(index)">
					{{check_message}}
				</div>
			</div>
			<q-card-actions v-if="messages.length > 5" class="q-pa-none">
				<q-btn flat round size="sm" icon="more_horiz" @click="show_all = !show_all" />
			</q-card-actions>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn v-if="messages.length > 5" color="primary" flat label="Send report by email" @click="sendByEmail"/>
			<q-btn color="primary" flat label="Close" @click="$emit('close')"/>
		</q-card-actions>
	</q-card>
</template>
<script>
import { mapGetters } from 'vuex';
import MessageFormsAPI from '@/services/api/MessageForm.js';
export default {
	name:"CheckDatesMessage",
	props:{
		messages: Array,
	},
	data(){
		return{
			show_all: false,
		}
	},
	computed: {
		...mapGetters(['logged_user']),
	},
	methods: {
		async sendByEmail() {
			try {
				await MessageFormsAPI.sendMessagesByEmail({"messages": this.messages})
				this.$q.notify('You will receive a message on ' + this.logged_user.email + '.')
			} catch(error) {
				this.$q.notify({
					timeout: 6700,
					message: 'Email creation failed.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
			this.$emit('close')
		},
	}
}
</script>