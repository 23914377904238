<template>
	<div style="width:100%;">
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Only this one:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="only_one_session"
					@input="changeSliderOne"
				/>
			</q-item-section>
		</q-item>
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">All the following messages:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="all_sesions"
					@input="changeSliderAll"
				/>
			</q-item-section>
		</q-item>
	</div>
</template>

<script>
export default {
	name: 'Sliders',

	data(){
		return {
			only_one_session: true,
			all_sesions: false,
			choice: null,
			is_choice_picked: false
		};
	},
	methods:{
		changeSliderOne(){
			this.all_sesions = !this.only_one_session
			if(this.only_one_session){
				this.choice=1
			}else{
				this.choice=0
			}
			this.$emit("update-reschedule-mode",this.choice)
		},
		changeSliderAll(){
			this.only_one_session = !this.all_sesions
			if(this.all_sesions){
				this.choice=0
			}else{
				this.choice=1
			}
			this.$emit("update-reschedule-mode",this.choice)
		},
	}
}
</script>