<template>
	<div class="row">
		<q-select
			v-model="_selected_country"
			:options="filter_options_c"
			:class="subdivisions.length ? 'col-sm-6 col-12' : 'col-12'"
			label="Country"
			name="country"
			:error="has_selected_country_error"
			error-message="Please select a country"
			use-input
			@filter="filterCountry"
		/>
		<q-select
			v-if="subdivisions.length"
			v-model="_selected_subdivision"
			:options="filter_options_s"
			class="col-sm-6 col-12"
			label="Subdivision:"
			name="subdivision"
			:error="has_selected_subdivision_error"
			error-message="Please select a subdivision"
			use-input
			@filter="filterSubdivision"
		/>
	</div>
</template>

<script>
import SessionsAPI from '@/services/api/Sessions.js';

export default {
	name: 'SelectCountry',
	props: {
		countries: Array,
		selected_country: String,
		selected_subdivision: String,
		has_selected_country_error: Boolean,
		has_selected_subdivision_error: Boolean
	},

	data() {
		return {
			subdivisions: [],
			filter_options_c: [],
			filter_options_s: []
		};
	},

	computed: {
		_selected_country: {
			get() {
				return this.selected_country
			},
			async set(val) {
				this.updateValues('holiday_list', [])
				this.updateValues('selected_country', val)
				await this.getSubdivisions(val)
			}
		},
		_selected_subdivision: {
			get() {
				return this.selected_subdivision
			},
			set(val) {
				this.updateValues('selected_subdivision', val)
			}
		}
	},

	methods: {
		async getSubdivisions(country){
			this.subdivisions = []
			this.updateValues('selected_subdivision', '')
			const response = await SessionsAPI.getScheduleSessionCountrySubdivisions(country)
			this.subdivisions = response.subdivisions
			this.$emit('update-has-subdivision', this.subdivisions.length ? true : false)
		},
		updateValues(type, value){
			this.$emit('update-values', type, value)
		},
		filterCountry (val, update, type) {
			if (val === '') {
				update(() => {
					this.filter_options_c = this.countries
				})
				return
			}
			update(() => {
				const needle = val.toLowerCase()
				this.filter_options_c = this.countries.filter(obj => obj.toLowerCase().includes(needle) )
			})
		},
		filterSubdivision (val, update, type) {
			if (val === '') {
				update(() => {
					this.filter_options_s = this.subdivisions
				})
				return
			}
			update(() => {
				const needle = val.toLowerCase()
				this.filter_options_s = this.subdivisions.filter(obj => obj.toLowerCase().includes(needle) )
			})
		}
	},
};
</script>