<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section>
			<div class="text-h6">Schedule message</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<SuccessErrorMessage :message="message"/>
			<q-item>
				<q-input
					outlined
					v-model="date_time"
					name="date_time"
					class="full-width"
					label="Schedule for*"
					hint="Select Date and Time. Format DD/MM/YYYY HH:MM:SS"
					:error="has_date_error"
					error-message="Please choose a date and time"
					readonly
				>
					<template v-slot:append>
						<q-btn flat icon="event" class="cursor-pointer date-btn">
							<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
								<q-date
									v-model="date_time"
									mask="DD/MM/YYYY HH:mm:ss"
									:options="limitDate"
								>
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Close" color="primary" flat />
									</div>
								</q-date>
							</q-popup-proxy>
						</q-btn>
						<q-btn flat icon="access_time" class="cursor-pointer date-btn">
							<q-popup-proxy transition-show="scale" transition-hide="scale">
								<q-time
									v-model="date_time"
									mask="DD/MM/YYYY HH:mm:ss"
									format24h
									color="primary"
								>
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Close" color="primary" flat />
									</div>
								</q-time>
							</q-popup-proxy>
						</q-btn>
					</template>
				</q-input>
			</q-item>
			<q-item>
				<CountriesTimeZoneDropdown
					class="full-width"
					:preferred_timezone="preferred_timezone"
					:has_timezone_error="has_timezone_error"
					@update-timezone="setTimezone"
				/>
			</q-item>
			<Sliders
				v-if="recurrent_session_id && !show_recurrent_message_fields"
				@update-reschedule-mode="updateRescheduleMode"
			/>
			<RecurringMessage
				v-if="show_recurrent_message_fields"
				:date_time="date_time"
				:calendar_selected_zone="calendar_selected_zone"
				@update-recurrent-data="updateRecurrentData"
				@is-recurrent="updateIsRecurrent"
				@update-values="updateValues"
			/>
			<HolidaySliders
				v-if="show_recurrent_message_fields"
				:check_holiday="check_holiday"
				:reschedule_holiday="reschedule_holiday"
				:selected_country="selected_country"
				:selected_subdivision="selected_subdivision"
				:has_subdivision="has_subdivision"
				:has_selected_country_error="has_selected_country_error"
				:has_selected_subdivision_error="has_selected_subdivision_error"
				:has_reschedule_holiday_error="has_reschedule_holiday_error"
				@update-values="updateValues"
			/>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('close')"/>
			<q-btn color="primary" flat label="Schedule" @click="saveAndScheduleSession()" :disable="is_disabled"/>
		</q-card-actions>
	</q-card>
</template>

<script>

import { mapGetters } from 'vuex';
import { date } from 'quasar'
import CountriesTimeZoneDropdown from "@/components/scenarios/calendar_reports/CountriesTimeZoneDropdown.vue";
import HolidaySliders from "@/components/scenarios/calendar_reports/HolidaySliders.vue";
import SessionsAPI from "@/services/api/Sessions.js";
import Sliders from "@/components/scenarios/calendar_reports/Sliders.vue";
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import RecurringMessage from "@/components/message_forms/RecurringMessage.vue";

export default {
	name: "ScheduleModal",
	components: {
		CountriesTimeZoneDropdown,
		HolidaySliders,
		Sliders,
		SuccessErrorMessage,
		RecurringMessage
	},
	props: {
		session: Object,
		preferred_timezone: String,
		recurrent_session_id: Number,
		scenario_name: String,
		active_tab: String,
		show_recurrent_message_fields: Boolean,
	},

	data() {
		return {
			time_zone: "",
			date_time: "",
			error_message: "",
			reschedule_mode: 1,
			scenario_type_name: "",
			message:{},
			check_holiday: false,
			reschedule_holiday: false,
			check_weekend: false,
			reschedule_weekend: false,
			selected_country: '',
			selected_subdivision: '',
			holiday_list: [],
			has_subdivision: false,
			has_date_error: false,
			has_timezone_error: false,
			has_selected_country_error: false,
			has_selected_subdivision_error: false,
			has_reschedule_holiday_error: false,
			date_today: '',
			is_disabled: false
		}
	},
	computed: {
		...mapGetters(['current_CG']),
		date_time_format: function () {
			return {
				weekday: "short",
				month: "short",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			};
		},
		calendar_selected_zone: function () {
			try {
				return this.time_zone.toString();
			} catch (error) {
				return null;
			}
		},
	},
	methods: {
		updateIsRecurrent(val){
			this.$emit('update-is-recurrent', val);
		},
		updateRecurrentData(obj){
			this.$emit('update-recurrent-data', obj);
		},
		updateRescheduleMode(mode) {
			this.reschedule_mode = mode;
		},
		updateValues(type, value){
			this[type] = value
			this.$emit('update-values', {'type': type, 'value': value})
		},
		setTimezone(timezone) {
			this.time_zone = timezone;
		},
		validateData(){
			let has_error = false
			if (!this.time_zone) {
				this.has_timezone_error = true
				has_error = true
			}
			if (!this.date_time) {
				this.has_date_error = true
				this.date_error_message = ""
				has_error = true
			}
			if ((this.reschedule_holiday || this.check_holiday) && !this.selected_country) {
				this.has_selected_country_error = true
				has_error = true
			}
			if(this.reschedule_holiday && this.has_subdivision && !this.selected_subdivision){
				this.has_selected_subdivision_error = true
				has_error = true
			}
			if (this.reschedule_holiday && !this.holiday_list.length) {
				this.has_reschedule_holiday_error = true
				has_error = true
			}
			return has_error
		},
		resetErrors(){
			this.has_date_error = false
			this.has_timezone_error = false
			this.has_selected_country_error = false
			this.has_selected_subdivision_error = false
			this.has_reschedule_holiday_error = false

		},
		async scheduleSession(mode) {
			try {
				let date = this.date_time.split(' ')[0]
				let format_date = `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}`

				await SessionsAPI.rescheduleSession(this.session.id, {
					date_start: format_date + 'T' + this.date_time.split(' ')[1],
					preferred_timezone: this.time_zone,
					reschedule_mode: mode,
					check_holiday: this.check_holiday,
					reschedule_holiday: this.reschedule_holiday,
					check_weekend: this.check_weekend,
					reschedule_weekend: this.reschedule_weekend,
					selected_country: this.selected_country,
					selected_subdivision: this.selected_subdivision,
					ignore_holiday_list: this.holidays
				});
				this.message = {
					'type': 'success',
					'text': 'Your message was successfully scheduled.'
				}
				setTimeout(function () { this.$emit("close") }.bind(this), 3000);
			} catch (error) {
				this.message = {
					'type': 'error',
					'text': error.data
				}
			}
		},
		saveAndScheduleSession() {
			this.resetErrors()
			let has_error = this.validateData()
			if(has_error){
				return;
			}
			if(this.session && this.session.id){
				if (this.recurrent_session_id) {
					this.scheduleSession(this.reschedule_mode);
				} else {
					this.scheduleSession(1);
				}
			}else{
				let date = this.date_time.split(' ')[0]
				let format_date = `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}`
				let scheduled_data = {
					"datetime": format_date + 'T' + this.date_time.split(' ')[1],
					"timezone": this.time_zone
				}
				this.$emit('schedule-message', scheduled_data);
			}
		},
		limitDate(date){
			return date >= this.date_today.split(' ')[0]
		},
	},
	created() {
		if (this.session){
			this.time_zone = this.session.preferred_timezone
			let date = this.session.date_start.split('T')[0]
			let formated_date = `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`
			this.date_time = formated_date + ' ' + this.session.date_start.split('T')[1]
		}else{
			this.time_zone = this.preferred_timezone;
		}

		if (this.active_tab.toUpperCase() == 'TEST'){
			this.scenario_type_name = this.current_CG.customer_group.session_type_name.test_scenario_name
		}else if (this.active_tab.toUpperCase() == 'REAL'){
			this.scenario_type_name = this.current_CG.customer_group.session_type_name.real_scenario_name
		}else {
			this.scenario_type_name = 'all'
		}
		this.date_today = date.formatDate(Date.now(), 'YYYY/MM/DD HH:mm:ss')
	},
	watch: {
		date_time(val) {
			if (this.session) {
				let date = this.session.date_start.split('T')[0]
				let formated_date = `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`
				let session_time = formated_date + ' ' + this.session.date_start.split('T')[1]
				this.is_disabled = true
				if (val) {
					if (val != session_time) {
						this.is_disabled = false
					}
				}
			}
		},
		time_zone(val) {
			if (this.session) {
				this.is_disabled = true
				if (val != this.session.preferred_timezone) {
					this.is_disabled = false
				}
			}
		}

	}
};
</script>
<style lang="scss">
.date-btn .q-btn__wrapper {
	padding: 0.5rem;
}
</style>
