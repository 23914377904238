<template>
	<div>
		<q-select
			v-model="holiday"
			multiple
			use-chips
			:options="holidays"
			name="Bank holidays"
			label="Bank holidays"
			hint="Select a bank holiday to reschedule to next working day:"
			:error="has_reschedule_holiday_error"
			error-message="Please select a holiday"
			:option-label="(item) => item === null ? null : item.value"
		/>
		<a class="cursor-pointer" v-if="!selected_all" @click="selectAll()"><u>Select all holidays?</u></a>
		<a class="cursor-pointer" v-else @click="deselectAll()"><u>Unselect all holidays?</u></a>
	</div>
</template>

<script>
import SessionsAPI from '@/services/api/Sessions.js';
export default {
	name: 'IgnoreHoliday',
	props:{
		selected_country: String,
		selected_subdivision: String,
		has_reschedule_holiday_error: Boolean,
	},
	data () {
		return {
			holidays: [],
			holiday: null,
			selected_all: false
		}
	},
	methods:{
		async getHolidays(){
			this.holidays = []
			this.holiday = null
			const response = await SessionsAPI.getHolidays(this.selected_country, this.selected_subdivision);
			this.holidays = response
		},
		selectAll() {
			this.selected_all = true
			this.holiday = this.holidays
		},
		deselectAll() {
			this.selected_all = false
			this.holiday = null
		}
	},
	created(){
		this.getHolidays();
	},
	watch:{
		selected_country: function() {
			this.getHolidays();
		},
		selected_subdivision: function() {
			this.getHolidays();
		},
		holiday: function(){
			if (this.holiday){
				this.$emit('update-values', 'holiday_list', this.holiday.map(it => it.date))
			}
		},
	}
}
</script>
