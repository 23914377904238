<template>
	<div>
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Warn if schedule includes bank holidays:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="_check_holiday"
				/>
			</q-item-section>
		</q-item>
		<q-item tag="label" v-if="check_holiday && !called_from_check">
			<q-item-section>
				<q-item-label class="text-left">Reschedule bank holidays to working day:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="_reschedule_holiday"
				/>
			</q-item-section>
		</q-item>
		<q-item v-if="check_holiday">
			<SelectCountry
				class="full-width"
				:countries="countries"
				:selected_country="selected_country"
				:selected_subdivision="selected_subdivision"
				:has_selected_country_error="has_selected_country_error"
				:has_selected_subdivision_error="has_selected_subdivision_error"
				@update-values="updateValue"
				@update-has-subdivision="updateHasSubdivision"
			/>
		</q-item>
		<q-item v-if="display_holidays">
			<IgnoreHoliday
				class="full-width"
				:selected_country="selected_country"
				:selected_subdivision="selected_subdivision"
				:has_reschedule_holiday_error="has_reschedule_holiday_error"
				@update-values="updateValue"
			/>
		</q-item>
	</div>
</template>

<script>
import SelectCountry from "@/components/scenarios/calendar_reports/SelectCountry.vue";
import IgnoreHoliday from "@/components/scenarios/calendar_reports/IgnoreHoliday.vue";

import SessionsAPI from '@/services/api/Sessions.js';
export default {
	name: 'HolidaySliders',
	components: {
		SelectCountry,
		IgnoreHoliday,
	},
	props: {
		called_from_check: Boolean,
		check_holiday: Boolean,
		reschedule_holiday: Boolean,
		selected_country: String,
		selected_subdivision: String,
		has_subdivision: Boolean,
		has_selected_country_error: Boolean,
		has_selected_subdivision_error: Boolean,
		has_reschedule_holiday_error: Boolean
	},
	data(){
		return {
			has_country: false,
			countries: [],
		};
	},
	computed: {
		_check_holiday: {
			get() {
				return this.check_holiday
			},
			set(val) {
				this.updateValue('check_holiday', val)
				if (!val) {
					this.updateValue('reschedule_holiday', false)
				}
			}
		},
		_reschedule_holiday: {
			get() {
				return this.reschedule_holiday
			},
			set(val) {
				this.updateValue('reschedule_holiday', val)
			}
		},
		_has_subdivision: {
			get() {
				return this.has_subdivision
			},
			set(val) {
				this.updateValue('has_subdivision', val)
			}
		},
		_selected_subdivision: {
			get() {
				return this.selected_subdivision
			},
			set(val) {
				this.updateValue('selected_subdivision', val)
			}
		},
		display_holidays() {
			return (
				this.check_holiday &&
				this.reschedule_holiday &&
				!!this.selected_country &&
				(!this.has_subdivisions || !!this.selected_subdivision)
			)
		}
	},
	methods:{
		async getCountries(){
			const response = await SessionsAPI.getScheduleSessionCountry();
			this.countries = response.countries
		},
		updateValue(type, value){
			this.$emit("update-values", type, value)
		},
		updateHasSubdivision(val) {
			this.updateValue('has_subdivision', val)
		}

	},
	created(){
		this.getCountries();
	},
}
</script>