<template>
	<div>
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Recurring message:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="recurrent"
				/>
			</q-item-section>
		</q-item>
		<div v-if="recurrent">
			<q-item>
				<q-select
					v-model="repeat_mode"
					:options="repeat_modes"
					label="Repeats"
					class="full-width"
					@input='summary'
				/>
			</q-item>
			<q-item>
				<q-select
					v-if="['Daily', 'Weekly', 'Monthly', 'Yearly'].includes(repeat_mode)"
					v-model="repeat_step"
					:options="repeat_steps"
					label="Repeat every:"
					class="full-width"
					@input='summary'
				>
					<template v-slot:append>
						<span style="font-size:14px;">
							{{ repeat_mode_placeholder }}
						</span>
					</template>
				</q-select>
			</q-item>
			<!-- REPEAT ON: LIST OF 7 CHECHBOXES; VISIBLE ONLY IF REPEAT_MODE = WEEKLY-->
			<q-item v-if='repeat_mode=="Weekly"' class="row text-center items-center justify-between">
				<div>Repeat on:</div>
				<div class="column" style="max-width: fit-content;">
					<span>Mo</span>
					<q-checkbox v-model="weekdays.Monday" keep-color color="primary"/>
				</div>
				<div class="column" style="max-width: fit-content;">
					<span>Tu</span>
					<q-checkbox v-model="weekdays.Tuesday" keep-color color="primary"/>
				</div>
				<div class="column" style="max-width: fit-content;">
					<span>We</span>
					<q-checkbox v-model="weekdays.Wednesday" keep-color color="primary"/>
				</div>
					<div class="column" style="max-width: fit-content;">
					<span>Th</span>
					<q-checkbox v-model="weekdays.Thursday" keep-color color="primary"/>
				</div>
					<div class="column" style="max-width: fit-content;">
					<span>Fr</span>
					<q-checkbox v-model="weekdays.Friday" keep-color color="primary"/>
				</div>
					<div class="column" style="max-width: fit-content;">
					<span>Sa</span>
					<q-checkbox v-model="weekdays.Saturday" keep-color color="primary"/>
				</div>
					<div class="column" style="max-width: fit-content;">
					<span>Su</span>
					<q-checkbox v-model="weekdays.Sunday" keep-color color="primary"/>
				</div>
			</q-item>
			<!-- STARTS ON -->
			<q-item class="justify-between items-center">
				<label >Starts on:</label>
				<div>{{date_time | moment('timezone', calendar_selected_zone, 'ddd, MMM DD, HH:mm')}}</div>
			</q-item>
			<!-- ENDS ON -->
			<q-item>
				<q-input
					outlined
					v-model='end.occurrences'
					class="full-width"
					type="number"
					color="primary"
					label="Ends after"
					min='1'
					:disable='end.mode === 0'
					onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
				>
					<template v-slot:append>
						<span style="font-size:14px;">
							occurrences
						</span>
					</template>
				</q-input>
				<q-radio
					left-label
					keep-color
					v-model="end.mode"
					color="primary"
					:val='1'
				/>
			</q-item>
			<q-item class="justify-between">
				<q-input
					outlined
					readonly
					v-model="end_datetime"
					name="end_datetime"
					hint="Select Date and Time. Format DD/MM/YYYY HH:MM:SS"
					label="Ends on"
					class="full-width"
					:disable='end.mode === 1'
				>
					<template v-slot:append>
						<q-btn flat icon="event" class="cursor-pointer date-btn">
							<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
								<q-date
									v-model="end_datetime"
									mask="YYYY-MM-DD HH:mm:ss"
									emit-immediately
								>
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Close" color="primary" flat />
									</div>
								</q-date>
							</q-popup-proxy>
						</q-btn>
						<q-btn flat icon="access_time" class="cursor-pointer date-btn">
							<q-popup-proxy transition-show="scale" transition-hide="scale">
								<q-time
									v-model="end_datetime"
									mask="YYYY-MM-DD HH:mm:ss"
									format24h
									color="primary"
									emit-immediately
								>
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Close" color="primary" flat />
									</div>
								</q-time>
							</q-popup-proxy>
						</q-btn>
					</template>
				</q-input>
				<q-radio
					left-label
					keep-color
					v-model="end.mode"
					:val='0'
					color="primary"
				/>
			</q-item>
			<!-- Check weekends -->
			<CheckWeekendSliders
				@update-values="updateValues"
				:called_from_check="false"
			/>
			<!-- Summary -->
			<q-item class="row justify-between items-center">
				<label>Summary:</label>
				<div>{{ summary_text }}</div>
			</q-item>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone'
import CheckWeekendSliders from "@/components/scenarios/calendar_reports/CheckWeekendSliders.vue";

export default {
	name: 'RecurringMessage',
	components: {
		CheckWeekendSliders
	},
	props: {
		date_time: String,
		calendar_selected_zone: String
	},
	data() {
		return {
			repeat_mode: 'Daily',
			repeat_step: 1,
			recurrent: false,
			repeat_modes: ['Daily', 'Weekly', 'Monthly', 'Yearly'],
			repeat_steps: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
			repeat_mode_placeholders: ['days', 'weeks', 'months', 'years'],
			weekdays: {
				'Monday': false,
				'Tuesday': false,
				'Wednesday': false,
				'Thursday': false,
				'Friday': false,
				'Saturday': false,
				'Sunday': false
			},
			end: {'mode': 1, 'occurrences': 5},
			summary_text: "",
			has_additional_recurring_message_options: false,
			timestamp: "",
			end_datetime: ""
		}
	},
	computed: {
		...mapGetters(['current_CG']),
		repeat_mode_placeholder(){
			if(this.repeat_mode === 'Daily'){
				return 'days';
			}
			if(this.repeat_mode === 'Weekly'){
				return 'weeks';
			}
			if(this.repeat_mode === 'Monthly'){
				return 'months';
			}
			if(this.repeat_mode === 'Yearly'){
				return 'years';
			}
			if(this.repeat_mode.includes('hours') || this.repeat_mode.includes('hour')){
				return 'hours';
			}
			if(this.repeat_mode.includes('minutes')){
				return 'minutes';
			}
		},
	},
	methods: {
		summary() {
			let summary_repeat_mode = '';
			let summary_weekdays = '';
			let summary_end = '';

			//repeat mode
			if(this.repeat_step == 1){
				if(this.repeat_mode=='Yearly'){
					summary_repeat_mode = 'Annually';
				}
				else{
					summary_repeat_mode = this.repeat_mode;
				}
			}
			else if(this.repeat_step >1) {
				summary_repeat_mode = 'Every ' + this.repeat_step +' '+ this.repeat_mode_placeholder;
			}
			//weekdays
			if(this.repeat_mode=='Weekly'){
				let days_array = '';
				for(let i in this.weekdays){
					if(this.weekdays[i]){
						days_array += ' ' + i + ',';
					}
				}
				// remove the last comma from days_array
				if(days_array != ''){
					days_array = days_array.slice(0,-1);
					summary_weekdays = ', on' + days_array;
				}
			}
			//end_mode
			if(this.end.mode==0 && this.end_datetime != undefined && this.end_datetime != ''){
				summary_end = ', until' + ' ' + moment(this.end_datetime).format('MMM DD, YYYY');
			}
			else if(this.end.occurrences != undefined){
				summary_end = ', ' + this.end.occurrences + ' times';
			}

			this.summary_text = summary_repeat_mode + summary_weekdays + summary_end;
		},
		buildRecurrentData(){
			var obj = {};
			obj.repeat_mode = this.repeat_mode;
			obj.repeat_mode_placeholder = this.repeat_mode_placeholder;
			obj.repeat_step = this.repeat_step;
			// FIXME: The error message is never read
			let error_message = '';

			//validate
			obj.weekdays = this.weekdays;
			var weekdays_valid = false;
			for(var i in this.weekdays){
				if(this.weekdays[i]){
					weekdays_valid = true;
				}
			}
			if(!weekdays_valid && this.repeat_mode == 'Weekly'){
				error_message = 'Please choose at least one day of week.';
				return;
			}
			//validate
			obj.end = {};
			obj.end.mode = this.end.mode;

			if(this.end.mode==0){

				//compare
				if(this.end_datetime != '' && (this.date_time < this.end_datetime)){
					obj.end.end_datetime = moment(this.end_datetime).format('YYYY-MM-DDTHH:mm:ss');
				}
				else if(this.end_datetime == '' || this.end_datetime == undefined){
					error_message = 'End date can not be empty.';
					return;
				}
				else{
					error_message = 'End date can not be smaller or equal to the start date.';
					return;
				}
			}

			else if(this.end.mode==1){
				if(this.end.occurrences > 0) {
					obj.end.occurrences = Number.parseInt(this.end.occurrences);
				}
				else{
					// bad occurances exp
					error_message = 'Occurrences number not valid';
					return;
				}
			}
			this.$emit('update-recurrent-data', obj);
		},
		updateValues(type, value){
			this.$emit('update-values', type, value)
		}
	},
	created() {
		if(this.current_CG.root_customer_group_permissions.includes('Additional recurring message options')){
			this.has_additional_recurring_message_options = true;
		}
		if(this.has_additional_recurring_message_options) {
			this.repeat_modes.unshift('15 minutes', '30 minutes', '1 hour', '2 hours', '4 hours', '8 hours', '12 hours', '16 hours', '20 hours');
			this.repeat_mode_placeholders.unshift('minutes', 'hours');
		}
		this.timestamp = new Date().toISOString();

		let day_of_week = moment().day();
		if(day_of_week == 0){ this.weekdays.Sunday = true;}
		else if(day_of_week == 1){ this.weekdays.Monday = true;}
		else if(day_of_week == 2){ this.weekdays.Tuesday = true;}
		else if(day_of_week == 3){ this.weekdays.Wednesday = true;}
		else if(day_of_week == 4){ this.weekdays.Thursday = true;}
		else if(day_of_week == 5){ this.weekdays.Friday = true;}
		else if(day_of_week == 6){ this.weekdays.Saturday = true;}
		this.summary();
		this.buildRecurrentData();
	},
	watch: {
		weekdays: {
			deep: true,
			handler() {
				this.summary();
				this.buildRecurrentData();
			}
		},
		end: {
			deep: true,
			handler() {
				this.summary();
				this.buildRecurrentData();
			}
		},
		end_datetime: function(){
			this.summary();
			this.buildRecurrentData();
		},
		recurrent: function(val) {
			this.$emit('is-recurrent', val);
		},
		repeat_step: function() {
			this.summary();
			this.buildRecurrentData();
		},
		repeat_mode: function() {
			this.summary();
			this.buildRecurrentData();
		}
	},
};
</script>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
