<template>
	<div class="row justify-between">
		<q-select
			v-model="selected_country"
			:options="countries"
			class="col-sm-5 col-12"
			label="Country"
			name="country"
			hint="Pick a country and filter its timezones"
			:error="has_timezone_error"
			error-message="Please choose a country"
			@popup-hide="getCountries"
		/>
		<q-select
			v-model="selected_timezone"
			:options="country_timezones"
			class="col-sm-5 col-12"
			label="Timezone"
			name="timezone"
			hint="Pick a timezone from the selected country"
			:error="has_timezone_error"
			error-message="Please choose a timezone"
			@popup-hide="$emit('update-timezone', selected_timezone)"
		/>
	</div>
</template>

<script>
import TimeZones from "@/utils/countries_timezones.js";

export default {
	props: {
		preferred_timezone: String,
		has_timezone_error: Boolean,
	},

	data() {
		return {
			selected_country: "",
			selected_timezone: "",
			country_timezones: [],
			countries: [],
			time_zones: [],
		};
	},
	methods: {
		createCountriesList() {
			this.time_zones.forEach((time_zone) => {
				this.countries.push(time_zone.name);
			});
		},
		getSessionCountry: function (selected_timezone) {
			this.time_zones.forEach((time_zone) => {
				if (time_zone.timezones == selected_timezone) {
					this.selected_country = time_zone.name;
				}
			});
		},
		getCountries() {
			this.selected_timezone = "";
			this.time_zones.forEach((time_zone) => {
				if (time_zone.name == this.selected_country) {
					this.country_timezones = time_zone.timezones;
				}
				if (this.country_timezones.length == 1) {
					this.selected_timezone = this.country_timezones[0];
				} else {
					this.selected_timezone = "";
				}
			});
			this.$emit("update-timezone", this.selected_timezone);
		},
	},
	created() {
		this.selected_timezone = this.preferred_timezone;
		this.time_zones = TimeZones.getTimeZones();
		this.createCountriesList();
		this.getSessionCountry(this.selected_timezone);
	},
};
</script>
