<template>
	<div>
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Warn if schedule includes weekends:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="check_weekend" />
			</q-item-section>
		</q-item>
		<q-item v-if="check_weekend && !called_from_check" tag="label">
			<q-item-section>
				<q-item-label class="text-left">Reschedule weekends to weekday:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="reschedule_weekend"
					@input="updateValues('reschedule_weekend', reschedule_weekend)"
				/>
			</q-item-section>
		</q-item>
	</div>
</template>

<script>
import SessionsAPI from '@/services/api/Sessions.js';
export default {
	name: 'CheckWeekendSliders',
	props:{
		called_from_check: Boolean
	},
	data(){
		return {
			check_weekend: false,
			reschedule_weekend: false,
		};
	},
	methods:{
		updateValues(type, value){
			this.$emit('update-values', type, value)
		},
		async getCountries(){
			const response = await SessionsAPI.getScheduleSessionCountry();
			this.countries = response.countries
		},
	},
	created(){
		this.getCountries();
	},
	watch: {
		check_weekend: function(){
			if (!this.check_weekend){
				this.reschedule_weekend = false
				this.updateValues('reschedule_weekend', this.reschedule_weekend)
			}
			this.updateValues('check_weekend', this.check_weekend)
		}
	}
}
</script>